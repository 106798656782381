import { useSelector } from 'react-redux';
import { State } from '../reducers';
import { getHeroMediaSource } from 'utils/media';

export function useHeroMedia(presetSuffix: string) {
	const { heroImageDesktop, heroImageMobile, heroImageOffsetY, heroMediaType } = useSelector(({ resource }: State) => ({
		heroImageDesktop: resource.heroImageDesktop,
		heroImageMobile: resource.heroImageMobile,
		heroImageOffsetY: resource.heroImageOffsetY,
		heroMediaType: resource.heroMediaType,
	}));

	return {
		heroMedia: getHeroMediaSource(heroMediaType, heroImageDesktop, heroImageMobile, presetSuffix),
		heroImageOffsetY,
	};
}
